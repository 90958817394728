import React from 'react'
import './App.scss'

import { RichText } from '../RichText/RichText'

const App = () => (
  <div className="App">
    <main>
      <RichText>
        <p>
          Olá.<br />
          Seja bem vindo.
        </p>
        <p>
          Meu nome é Simon e esta é a minha biblioteca. Aqui guardo pensamentos que me ajudam a entender melhor o mundo e a mim mesmo.
        </p>
        <ul>
          <li>
            <a href="https://simonfan.com.br/china-julho-2018/">
              1. ORIGENS
            </a>
          </li>
          <li>
            <a href="https://simonfan.com.br/metaesquema-meta/">
              METAESQUEMAS
            </a>
          </li>
          <li>
            <a href="https://simonfan.com.br/blokoke-2020/">
              CARNAVAL - BLÓKÕKÊ
            </a>
          </li>
          <li>
            <a href="https://simonfan.com.br/turquia-2019/#/01-mar-negro">
              TURQUIA / 01 - MAR NEGRO
            </a>
          </li>
          <li>
            <a href="mailto:simon@orioro.design">simon@orioro.design</a>
          </li>
        </ul>
      </RichText>
    </main>
  </div>
)

export default App
